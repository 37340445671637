const en = {
    //Home
    greeting: "Welcome",
    description: "This is a brief description",
    fantasyButton: "Button to the Light Side",
    horrorButton: "Button to the Dark Side",
    //Fantasy
    fantasyTitle: "",
    fantasyDescription: "",
    //Horror
    horrorTitle: "",
    horrorDescription: "",
    //About
    aboutTitle: "About Workshop",
    aboutDescription: "We create unique masks and costumes for your fantasy and horror worlds.",
    //Order
    orderTitle: "How to order",
    orderDescription: "Contact us to place an order for unique products.",
    //Header
    vkContact: 'Contact in VK',
    home: 'Home',
    about: 'About Workshop',
    howToOrder: 'How to Order',
    lightSideButton: 'Button to the Light Side',
    toFantasy: "",
    toHorror: "",
    //Admin
    adminTitle: "Admin Panel",
    loginLabel: "Login",
    passwordLabel: "Password",
    loginButton: "Sign In",
    registerButton: "Register",
    //Album
    albumTitle: "Album Title",
    albumButton: "Go to Album",
    showPhotos: 'Show Photos',
    hidePhotos: 'Hide Photos',  
  };
  
  export default en;
  
  