import styled from 'styled-components';
import { theme } from '../theme';

export const FooterContainer = styled.footer`
  background-color: ${theme.colors.fantasySecondary};
  color: ${theme.colors.textPrimary};
  text-align: center;
  padding: 20px 10px;
  margin-top: 20px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 30px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 40px;
  }
`;

export const FooterText = styled.p`
  margin: 0;
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.textSecondary};

  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSize.medium};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    font-size: ${theme.fontSize.large};
  }
`;

export const FooterLinks = styled.div`
  margin-top: 10px;
  a {
    color: ${theme.colors.textPrimary};
    margin: 0 10px;
    text-decoration: none;
    font-size: ${theme.fontSize.medium};

    &:hover {
      text-decoration: underline;
      color: ${theme.colors.fantasyAccent};
    }
  }

  @media (min-width: ${theme.breakpoints.tablet}) {
    a {
      font-size: ${theme.fontSize.large};
    }
  }
`;
