import styled from 'styled-components';
import { theme } from '../theme';

export const AlbumWrapper = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: ${theme.colors.fantasyNeutral};
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 30px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 40px;
  }
`;

export const AlbumTitle = styled.h2`
  font-size: ${theme.fontSize.large};
  margin-bottom: 10px;
  color: ${theme.colors.fantasyPrimary};

  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: ${theme.fontSize.large};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    font-size: calc(${theme.fontSize.large} + 2px);
  }
`;

export const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: ${theme.breakpoints.tablet}) {
    gap: 15px;
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    gap: 20px;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  gap: 20px;
  z-index: 1010; /* Выше затемнения */

  img {
    max-width: 70%;
    max-height: 90vh; /* Ограничение по высоте экрана */
    object-fit: contain; /* Сохраняем пропорции, избегаем обрезания */
    border-radius: 8px;
  }

  div {
    flex-grow: 1; /* Заполняет оставшееся пространство */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: ${theme.fontSize.medium};
      color: ${theme.colors.textSecondary};
      margin: 0; /* Убираем лишние отступы */
      text-align: left;
    }
  }

  @media (min-width: ${theme.breakpoints.tablet}) {
    max-width: 70%;
    max-height: 80%; /* Для планшетов немного уменьшаем размеры */
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    max-width: 60%;
    max-height: 70%; /* Для больших экранов еще меньше */
  }
`;

export const PhotoItem = styled.div`
  width: 150px;
  height: 150px; /* Увеличиваем превью */
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${theme.colors.fantasyAccent};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 200px;
    height: 200px; /* Больше на планшетах */
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 250px;
    height: 250px; /* Еще больше на больших экранах */
  }
`;


