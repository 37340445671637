import api from "./axios";

// Получить список альбомов
export const getAlbums = async () => {
  const response = await api.get('/albums');
  return response.data;
};

// Создать альбом
export const createAlbum = async (data: { title: string; description: string }) => {
  const response = await api.post('/albums', data);
  return response.data;
};

// Обновить альбом
export const updateAlbum = async (albumId: number, data: { title: string; description?: string }) => {
  const response = await api.patch(`/albums/${albumId}`, data);
  return response.data;
};

// Удалить альбом
export const deleteAlbum = async (albumId: number) => {
  const response = await api.delete(`/albums/${albumId}`);
  return response.data;
};

// Добавить изображение в альбом
export const addImage = async (albumId: number, file: File, description: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);

  const response = await api.post(`/albums/${albumId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Удалить изображение
export const deleteImage = async (imageId: number) => {
  const response = await api.delete(`/albums/images/${imageId}`);
  return response.data;
};
