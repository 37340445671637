import axios from 'axios';
import configEnv from '../config';

const api = axios.create({
  baseURL: configEnv.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 3000,
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('access_token'); // Получаем токен из sessionStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовки
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // Обработка ошибок
  }
);

export default api;
