import React from 'react';
import { FooterContainer, FooterText, FooterLinks } from '../styles/components/footerStyles';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>&copy; {new Date().getFullYear()} Horn Mask Shop. Все права защищены.</FooterText>
      <FooterLinks>
        <a href="/about">О нас</a>
        <a href="/order">Как заказать</a>
        <a href="/contact">Контакты</a>
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
