import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import localeStore from '../stores/localeStore';
import { AdminWrapper, LoginForm, Input, Button, PasswordContainer, TogglePasswordButton } from '../styles/pages/adminStyles'; 
import { loginUser, registerUser } from '../api/auth';
import configEnv from '../config';

const Admin: React.FC = observer(() => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showRegister, setShowRegister] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect (() => {
    const token = sessionStorage.getItem('access_token'); // Используем sessionStorage
    if (token) {
      navigate('/admin-dashboard'); // Переход на админ-панель
    }
  }, [navigate]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin(); // Вызов функции при нажатии Enter
    }
  };

  const handleLogin = async () => {
    if (!username && password === configEnv.regButton) {
      setShowRegister(true);
      return; 
    }

    try {
      const token = await loginUser(username, password);
      sessionStorage.setItem('access_token', token);
      navigate('/admin-panel'); // Переход на страницу админ-панели
    } catch (error) {
      alert('Неверный логин или пароль');
    }
  };

  const handleRegister = async () => {
    try {
      await registerUser(username, password);
      alert('Регистрация успешна');
      setShowRegister(false);
    } catch (error) {
      alert('Ошибка регистрации');
    }
  };

  return (
    <AdminWrapper onKeyDown={handleKeyDown}>
      <h1>{localeStore.translations.adminTitle}</h1> {/* Локализованный заголовок */}

      <LoginForm>
        <label>{localeStore.translations.loginLabel}</label>
        <Input
          type="text"
          value={username}
          placeholder="Введите почту"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <label>{localeStore.translations.passwordLabel}</label>
        <PasswordContainer>
          <Input
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            placeholder="Введите пароль"
            onChange={(e) => setPassword(e.target.value)}
          />
          <TogglePasswordButton
            type="button"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? '👁️' : '🙈' }
          </TogglePasswordButton>
        </PasswordContainer>
        <Button onClick={handleLogin}>
          {localeStore.translations.loginButton}
        </Button>
        {showRegister && (
          <Button onClick={handleRegister}>
            {localeStore.translations.registerButton}
          </Button>
        )}
      </LoginForm>
    </AdminWrapper>
  );
});

export default Admin;
