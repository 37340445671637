import styled from 'styled-components';
import { theme } from '../theme';

export const AdminPanelWrapper = styled.div`
  padding: 20px;
`;

export const AlbumList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const AlbumItem = styled.div`
  border: 1px solid ${theme.colors.fantasyAccent};
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: ${theme.colors.fantasySecondary};
    color: ${theme.colors.textPrimary};
  }
`;

export const Form = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid ${theme.colors.fantasyAccent};
  border-radius: 4px;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.fantasyPrimary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.fantasySecondary};
  }
`;
