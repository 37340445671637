import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Home from './pages/Home';
import About from './pages/About';
import Order from './pages/Order';
import Admin from './pages/Admin';
import AdminPanel from './pages/AdminPanel';


import localeStore from './stores/localeStore';
import { AppContainer } from './styles/appStyles';

const App: React.FC = observer(() => {
  useEffect(() => {
    localeStore.loadLocale();
  }, []);

  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/order" element={<Order />} />
          <Route path="/admin" element={<Admin />} /> 
          <Route path="/admin-panel" element={<AdminPanel />} />
        </Routes>
      </Router>
    </AppContainer>
  );
});

export default App;


