import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { getAlbums, createAlbum, updateAlbum, deleteAlbum, addImage, deleteImage } from '../api/albums';
import { AdminPanelWrapper, AlbumList, AlbumItem, Form, Input, Button } from '../styles/pages/adminPanelStyles';
import { Image } from '../types/apiTypes';
const AdminPanel: React.FC = observer(() => {
  const [albums, setAlbums] = useState<any[]>([]);
  const [selectedAlbum, setSelectedAlbum] = useState<number | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newAlbumTitle, setNewAlbumTitle] = useState('');
  const [newAlbumDescription, setNewAlbumDescription] = useState('');
  const [newImage, setNewImage] = useState<{ url: string; description: string; file: File | null; }>({ url: '', description: '', file: null });
  const [showImageForm, setShowImageForm] = useState(false);


  const fetchAlbums = async () => {
    const albums = await getAlbums();
    setAlbums(albums);
  };

  useEffect(() => {
    fetchAlbums();
  }, []);

  const handleCreateAlbum = async (title: string, description: string) => {
    try {
      await createAlbum({ title, description }); // Вызов API для создания альбома
      alert('Альбом успешно создан');
      // Обновление списка альбомов после создания
      fetchAlbums();
    } catch (error) {
      console.error('Ошибка при создании альбома:', error);
      alert('Не удалось создать альбом');
    }
  };
  

  const handleUpdateAlbum = async (albumId: number) => {
    const updatedTitle = prompt('Введите новое название альбома');
    const updatedDescription = prompt('Введите новое описание альбома');
    if (updatedTitle) {
      await updateAlbum(albumId, { title: updatedTitle, description: updatedDescription || '' });
      fetchAlbums();
    }
  };

  const handleDeleteAlbum = async (albumId: number) => {
    setShowImageForm(false); // Скрыть форму
    await deleteAlbum(albumId);
    fetchAlbums(); // Обновить список альбомов
  };
  

  const handleAddImage = async () => {
    if (!selectedAlbum || !newImage.file) {
      alert('Выберите альбом и изображение');
      return;
    }
  
    try {
      await addImage(selectedAlbum, newImage.file, newImage.description);
      alert('Изображение успешно добавлено');
      setNewImage({ url: '', description: '', file: null }); // Сбрасываем поля
      fetchAlbums(); // Обновляем альбомы
    } catch (error) {
      console.error('Ошибка при добавлении изображения:', error);
      alert('Не удалось добавить изображение');
    }
  };
  
  

  const handleDeleteImage = async (imageId: number) => {
    if (window.confirm('Вы уверены, что хотите удалить изображение?')) {
      await deleteImage(imageId);
      fetchAlbums();
    }
  };

  const handleToggleCreateForm = () => {
    setIsCreating(true); // Переключение формы
    setShowImageForm(false);
    setNewAlbumTitle('');
    setNewAlbumDescription('');
  };

  const handleSubmitNewAlbum = () => {
    handleCreateAlbum(newAlbumTitle, newAlbumDescription);
    handleToggleCreateForm(); // Закрытие формы после создания
  };

  const handleAlbumClick = (albumId: number) => {
    setIsCreating(false)
    setSelectedAlbum(albumId);
    setShowImageForm(true); // Показываем форму при выборе альбома
  };

  return (
    <AdminPanelWrapper>
      <h1>Управление Альбомами</h1>

      {/* Список альбомов */}
      <AlbumList>
        {albums.map((album) => (
          <AlbumItem key={album.id} onClick={() => handleAlbumClick(album.id)}>
            <h3>{album.title}</h3>
            <p>{album.description}</p>
            <Button onClick={() => handleUpdateAlbum(album.id)}>Редактировать</Button>
            <Button onClick={() => handleDeleteAlbum(album.id)}>Удалить</Button>
          </AlbumItem>
        ))}
        <AlbumItem onClick={handleToggleCreateForm}>
          <h3>+ Создать новый альбом</h3>
        </AlbumItem>
      </AlbumList>

      {/* Создание нового альбома */}
      {isCreating && (
        <Form>
          <h2>Создать новый альбом</h2>
          <Input
            type="text"
            placeholder="Название альбома"
            value={newAlbumTitle}
            onChange={(e) => setNewAlbumTitle(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Описание альбома"
            value={newAlbumDescription}
            onChange={(e) => setNewAlbumDescription(e.target.value)}
          />
          <Button onClick={handleSubmitNewAlbum}>Создать</Button>
          <Button onClick={handleToggleCreateForm}>Отмена</Button>
        </Form>
      )}

      {/* Добавление фотографий */}
      {selectedAlbum && showImageForm && (
        <>
            <Form>
              <h2>Добавить фотографию в альбом "{albums.find((album) => album.id === selectedAlbum)?.title}"</h2>
              <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                      setNewImage({ ...newImage, file });
                      }
                  }}
              />
              <Input
                  type="text"
                  placeholder="Описание изображения"
                  value={newImage.description}
                  onChange={(e) => setNewImage({ ...newImage, description: e.target.value })}
              />
              <Button onClick={handleAddImage}>Добавить</Button>
            </Form>

            <h2>Изображения в альбоме "{albums.find((album) => album.id === selectedAlbum)?.title}"</h2>
            <AlbumList>
            {albums
                .find((album) => album.id === selectedAlbum)
                ?.images.map((image: Image) => (
                <AlbumItem key={image.id}>
                    <img src={image.url} alt={image.description} style={{ width: '100%' }} />
                    <p>{image.description}</p>
                    <Button onClick={() => handleDeleteImage(image.id)}>Удалить</Button>
                </AlbumItem>
                ))}
            </AlbumList>
        </>
        )}
    </AdminPanelWrapper>
  );
});

export default AdminPanel;
