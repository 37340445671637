import React, { useEffect, useState } from 'react';
import { AlbumWrapper, AlbumTitle, PhotosWrapper, PhotoItem, ModalContent, ModalOverlay } from '../styles/components/AlbumStyles';
import { observer } from 'mobx-react-lite';
import { getAlbums } from '../api/albums';
import { Album as AlbumType, Image } from '../types/apiTypes';

const Album: React.FC = observer(() => {
  const [albums, setAlbums] = useState<AlbumType[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const data = await getAlbums();
        setAlbums(data);
      } catch (error) {
        console.error('Ошибка при загрузке альбомов:', error);
      }
    };

    fetchAlbums();
  }, []);

  const handleImageClick = (image: Image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      {albums.map((album) => (
        <AlbumWrapper key={album.id}>
          <AlbumTitle>{album.title}</AlbumTitle>
          <PhotosWrapper>
            {album.images.map((image) => (
              <PhotoItem key={image.id} onClick={() => handleImageClick(image)}>
                <img src={image.url} alt={image.description} />
              </PhotoItem>
            ))}
          </PhotosWrapper>
        </AlbumWrapper>
      ))}

      {selectedImage && (
        <div>
          <ModalOverlay onClick={handleCloseModal} />
          <ModalContent>
            <img src={selectedImage.url} alt={selectedImage.description} />
            <div>
              <p>{selectedImage.description}</p>
            </div>
          </ModalContent>
        </div>
      )}
    </div>
  );
});

export default Album;