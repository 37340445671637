const ru = {
    greeting: "Привет",
    description: "Это краткое описание",
    fantasyButton: "Кнопка на светлую сторону",
    horrorButton: "Кнопка на темную сторону",
    //Fantasy
    fantasyTitle: "Фэнтези",
    fantasyDescription: "Страница удивительных миров",
    //Horror
    horrorTitle: "Ужасы",
    horrorDescription: "Страница, заставляющая кровь застыть в жилах",
    //About
    aboutTitle: "О мастерской",
    aboutDescription: "Мы создаем уникальные маски и костюмы для ваших фэнтезийных и ужасающих миров.",
    //Order
    orderTitle: "Как заказать",
    orderDescription: "Свяжитесь с нами, чтобы сделать заказ на уникальные изделия.",
    //Header
    vkContact: 'Связь в ВК',
    home: 'Главная',
    about: 'О мастерской',
    howToOrder: 'Как заказать',
    lightSideButton: 'Кнопка на светлую сторону',
    toFantasy: "Светлая сторона",
    toHorror: "Темная сторона",
    //Admin
    adminTitle: "Администрирование",
    loginLabel: "Логин",
    passwordLabel: "Пароль",
    loginButton: "Войти",
    registerButton: "Регистрация",
    //Album
    albumTitle: "Album Title",
    albumButton: "Go to Album",
    showPhotos: 'Показать фотографии',
    hidePhotos: 'Скрыть фотографии',
  
  };
  
  export default ru;
  
  